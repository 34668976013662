import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer>
      <a href="https://github.com/2020PB/police-brutality">Source</a>
    </footer>
  );
}

export default Footer;
